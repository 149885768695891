body {
  margin: 0;
  overflow: auto;
  font-family: 'NotoSansRegular', 'NotoSansMedium', 'NotoSansBold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  resize: none;
}

textarea:focus {
  border: 1px solid #6bc7d3;
  background-color: #f2fcfd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'NotoSansRegular';
  src: local('NotoSansRegular'), url(./assets/fonts/NotoSansCJKkr-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'NotoSansMedium';
  src: local('NotoSansMedium'), url(./assets/fonts/NotoSansCJKkr-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'NotoSansBold';
  src: local('NotoSansBold'), url(./assets/fonts/NotoSansCJKkr-Bold.otf) format('truetype');
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
