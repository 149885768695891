:root {
  --white: #ffffff;
  --black: #000000;
  --end-call: #ef2957;
  --green-400: #00998c;
  --red-300: #f24d6b;
  --red-400: #d92148;
  --mute-gray: rgba(255, 255, 255, 0.2);
  --main-blue-01: #ddeeff;
  --main-blue-02: #a3d1ff;
  --point-blue-05: #036ad1;
  --point-blue-06: #2685fd;
  --dark-blue: #3881dc;
  --label-blue: #4d86bf;
  --grayscale-01: #f4f4f4;
  --grayscale-02: #eeeeee;
  --grayscale-03: #e0e0e0;
  --grayscale-04: #bdbdbd;
  --grayscale-05: #a3a3a3;
  --grayscale-06: #777777;
  --grayscale-07: #616161;
  --grayscale-08: #424242;
  --grayscale-09: #1f1f1f;
  --gray-border-01: #d8d8d8;
  --gray-border-02: #e5e5e5;
  --gray-background-01: #ededed;
  --gray-background-02: #f0f0f0;
  --sub-blue-01: #d4f3f7;
  --sub-blue-04: #42bbcb;
  --sub-blue-03: #399ba7;
  --sub-blue-05: #abe2ea;
  --sub-blue-06: #6bc7d3;
  --sub-blue-07: #42a8b5;
}
